import React from 'react';
import { injectIntl } from 'react-intl';
import { LanguageLayout } from '../containers/layouts';
import Wrapper from './../components/wrapper/';
import WrapperContent from './../components/wrapperContent/';

const Page404 = (props) => (
    <Wrapper>
        <WrapperContent>
            <h1>{props.intl.formatMessage({id: '404.title'})}</h1>
            <p>{props.intl.formatMessage({id: '404.message'})}</p>
        </WrapperContent>
    </Wrapper>
);

const Translated404 = injectIntl(Page404);

const NotFoundPage = (props) => (
  <LanguageLayout location={props.location} >
    <Translated404/>
  </LanguageLayout>
);

export default NotFoundPage;
